import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  getLayoutName,
  getTPASettingsIsPostPageSplitEnabled,
  resolveId,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import withDeviceType from '../../hoc/with-device-type';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';

import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPermissions from '../../hoc/with-permissions';
import withPostBorderWidth from '../../hoc/with-post-border-width';
import withTranslate from '../../hoc/with-translate';
import {
  getIsCategoryLabelsEnabled,
  getIsMoreButtonEnabled,
  getIsTagsEnabled,
} from '../../selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed } from '../../selectors/full-post-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import { getSection } from '../../selectors/section-selectors';
import { getPostActions } from '../../services/post-actions';
import {
  getIsDesktop,
  isEditor,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsPostInPreview } from '../../store/is-post-in-preview/is-post-in-preview-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../store/post-ratings/post-ratings-selector';
import CategoryLabelList from '../category-label-list';
import { MoreButton } from '../more-button';
import { importPostActions } from '../post-actions';
import PostContent from '../post-content';
import PostHeaderIcons from '../post-header-icons';
import PostMainActions from '../post-main-actions';
import PostPageMetadata from '../post-page-metadata';
import PostTitle from '../post-title';
import RatingsDisplay from '../ratings-display';
import { RatingsDisplayLayout } from '../ratings-display/ratings-display';
import { connect } from '../runtime-context';
import { TagsWithTranslation } from '../tags';
import PostUpdatedDate from './post-updated-date';
import styles from './post.scss';

export class Post extends React.Component {
  constructor(props) {
    super(props);

    this.preventTitleFocus = false;
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this.focusOnTitle();
  }

  focusOnTitle = () => {
    // const { isEditor, isInPostPage } = this.props;
    //
    // // TODO update after out of iframe, timeout is needed for accessibility audio indication on focus to work
    // if (!isEditor && isInPostPage) {
    //   this.preventTitleFocusOnTabKeyDown();
    //
    //   setTimeout(() => {
    //     if (!this.preventTitleFocus) {
    //       this.titleRef.current && this.titleRef.current.focus();
    //     }
    //     this.removeTabKeyDownListener();
    //   }, 2000);
    // }
  };

  preventTitleFocusOnTabKeyDown = () => {
    // document.addEventListener('keydown', this.tabKeyDownListener);
  };

  removeTabKeyDownListener = () => {
    // document.removeEventListener('keydown', this.tabKeyDownListener);
  };

  // eslint-disable-next-line
  tabKeyDownListener = e => {
    // if (e.keyCode === 9) {
    //   this.preventTitleFocus = true;
    //   this.removeTabKeyDownListener();
    // }
  };

  render() {
    const {
      applyFeedDesign,
      borderWidth,
      contentFontClassName,
      getPostClassName,
      isDesktop,
      isInPostPage,
      isMetadataHeaderVisible,
      isCategoryLabelsEnabled,
      post,
      postLink,
      commentsLink,
      showMoreButton,
      showPostUpdatedDate,
      showPostTitle,
      titleFontClassName,
      layoutName,
      isMoreButtonEnabled,
      isTagsEnabled,
      postPageMoreButtonClicked,
      shouldApplyPostDesignInFeed,
      postAverageRating,
      postTotalRatings,
      isPostRatingLoading,
      isMobile,
      showPostRating,
      onRatingsDisplayClick,
      isPostPageSplitEnabled,
      renodeRatings,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      contentFontClassName,
      'blog-card-background-color',
      'blog-card-border-color',
      'blog-text-color',
      getPostClassName('border-color', 'post-container', 'background-color'),
    );
    const moreButtonId = `more-button-${resolveId(post)}`;
    const titleContainerClassName = classNames(
      styles.title,
      !isMetadataHeaderVisible && styles.withoutHeaderMetadata,
      post.isPinned && styles.withIcons,
      !isInPostPage && styles.notPostPage,
      showMoreButton && isMoreButtonEnabled
        ? styles.withShowMoreButton
        : styles.withoutShowMoreButton,
    );

    const titleClassName = classNames(
      applyFeedDesign
        ? getPostClassName('title-font', 'title-color')
        : [titleFontClassName],
      'blog-post-title-color',
    );

    const metadataClassName = classNames(
      applyFeedDesign &&
        getPostClassName('description-font', 'description-color'),
    );
    const postContentClassName = classNames(
      styles.content,
      applyFeedDesign &&
        getPostClassName('description-style-font', 'description-color'),
    );

    const headerRightClass =
      isDesktop && (isInPostPage || shouldApplyPostDesignInFeed)
        ? classNames(styles.headerRight, styles.postPageDesktopHeader)
        : styles.headerRight;

    const RatingsWrapper = renodeRatings ? 'div' : 'section';
    return (
      <article
        className={containerClassName}
        style={{ borderWidth }}
        data-hook="post"
      >
        <div className={styles.contentWrapper}>
          {!isPostPageSplitEnabled && (
            <>
              <div className={styles.mobileContainer}>
                <div className={styles.header}>
                  {isMetadataHeaderVisible && (
                    <PostPageMetadata
                      type={layoutName}
                      post={post}
                      className={metadataClassName}
                      linkClassName={classNames(
                        getPostClassName('link-hashtag-hover-color'),
                      )}
                    />
                  )}
                  <div className={headerRightClass}>
                    <PostHeaderIcons post={post} />
                    {isMoreButtonEnabled && (
                      <MoreButton
                        className={classNames(
                          styles.moreButton,
                          styles.hideInPrint,
                        )}
                        id={moreButtonId}
                      >
                        {async () => {
                          postPageMoreButtonClicked({
                            postId: resolveId(post),
                          });
                          const PostActions = await importPostActions();
                          return (
                            <PostActions
                              post={post}
                              focusOnCloseId={moreButtonId}
                            />
                          );
                        }}
                      </MoreButton>
                    )}
                  </div>
                </div>
                {showPostTitle && (
                  <div
                    ref={this.titleRef}
                    tabIndex="-1"
                    className={titleContainerClassName}
                    data-hook="post-title"
                  >
                    <PostTitle
                      fullRoute={post.link}
                      isInPostPage={isInPostPage}
                      className={titleClassName}
                      type={PostTitle.SINGLE}
                      title={post.title}
                      to={postLink}
                      linkClassName={classNames(
                        getPostClassName('link-hashtag-hover-color'),
                      )}
                    />
                  </div>
                )}
              </div>
              {showPostUpdatedDate && <PostUpdatedDate post={post} />}
            </>
          )}
          {(isInPostPage
            ? showPostRating
            : showPostRating && !!postTotalRatings) && (
            <button
              className={styles.postRating}
              onClick={onRatingsDisplayClick}
            >
              <RatingsWrapper>
                <RatingsDisplay
                  rating={postAverageRating}
                  count={postTotalRatings}
                  isLoading={isInPostPage ? isPostRatingLoading : false}
                  useTransitions={renodeRatings || isPostRatingLoading}
                  layout={
                    isMobile
                      ? RatingsDisplayLayout.bracket_separated
                      : RatingsDisplayLayout.default
                  }
                />
              </RatingsWrapper>
            </button>
          )}
          <div className={postContentClassName} data-hook="post-description">
            <PostContent post={post} />
          </div>
          {!isPostPageSplitEnabled && (
            <div
              id="post-footer"
              className={classNames(styles.mobileContainer, styles.hideInPrint)}
            >
              {isTagsEnabled && (
                <TagsWithTranslation
                  postId={post.id}
                  isDemo={post.isDemo}
                  isPostPageTags={true}
                  tags={post.tags}
                  shouldSortTags={false}
                />
              )}
              {isCategoryLabelsEnabled && !isDesktop && (
                <div
                  className={styles.categoryList}
                  data-hook="post__categories"
                >
                  <CategoryLabelList post={post} />
                </div>
              )}

              <PostMainActions
                post={post}
                commentsLink={commentsLink}
                getPostClassName={getPostClassName}
                layoutName={layoutName}
              />
            </div>
          )}
        </div>
      </article>
    );
  }
}

Post.propTypes = {
  applyFeedDesign: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  getPostClassName: PropTypes.func,
  isDesktop: PropTypes.bool,
  isInPostPage: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  isCategoryLabelsEnabled: PropTypes.bool.isRequired,
  itemConfig: PropTypes.object,
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string,
  commentsLink: PropTypes.string,
  showMoreButton: PropTypes.bool,
  showPostUpdatedDate: PropTypes.bool,
  showPostTitle: PropTypes.bool,
  t: PropTypes.func,
  titleFontClassName: PropTypes.string.isRequired,
  layoutName: PropTypes.string,
  showMoreOptionsMenu: PropTypes.bool,
  isMoreButtonEnabled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isTagsEnabled: PropTypes.bool,
  moreButtonClicked: PropTypes.func,
  shouldApplyPostDesignInFeed: PropTypes.bool,
  showPostRating: PropTypes.bool,
  postAverageRating: PropTypes.number,
  postTotalRatings: PropTypes.number,
  isPostRatingPending: PropTypes.bool,
  isMobile: PropTypes.bool,
  onRatingsDisplayClick: PropTypes.func,
  renodeRatings: PropTypes.bool,
};

Post.defaultProps = {
  isMetadataHeaderVisible: true,
};

const mapRuntimeToProps = (state, { isInPostPage, post, canSee }, actions) => {
  const section = getSection(state) || SECTION_HOMEPAGE;
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const isPostInPreview = getIsPostInPreview(state);
  const showMoreButton = !isPostInPreview && Boolean(postActions.length);
  const postAverageRating = getPostAverageRating(state, resolveId(post));
  const postTotalRatings = getPostTotalRatings(state, resolveId(post));
  const isPostRatingLoading =
    typeof postAverageRating === 'undefined' &&
    typeof postTotalRatings === 'undefined';

  return {
    postLink: isInPostPage ? null : `/${post.slug}`,
    commentsLink: isInPostPage ? undefined : post.link,
    showMoreButton,
    shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed(state),
    layoutName: getLayoutName(getLayoutType(state, section)),
    isDesktop: getIsDesktop(state),
    isCategoryLabelsEnabled: getIsCategoryLabelsEnabled(state),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    isEditor: isEditor(state),
    isTagsEnabled: getIsTagsEnabled(state),
    postPageMoreButtonClicked: actions.postPageMoreButtonClicked,
    postAverageRating,
    postTotalRatings,
    isPostRatingLoading,
    isPostPageSplitEnabled: getTPASettingsIsPostPageSplitEnabled(state),
    /*
      We don't have ratings information in SSR and sometimes initial CSR render will have ratings fetched,
      which will result in hydration error. In that case we render ratings in different element for CSR
    */
    renodeRatings: !isSSR(state) && !isPostRatingLoading,
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withFeedMetadataSettings,
  withPostBorderWidth,
  withIsFeedDesignEnabled,
  withDeviceType,
)(Post);
