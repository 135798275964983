import { useSelector } from 'react-redux';
import { IHostProps } from '@wix/yoshi-flow-editor';
import { AppState } from '../../types';
import { WidgetState } from './widget-state.type';

export type SelectorFn<T> = (
  state: AppState,
  host: IHostProps,
  workerReady: boolean,
) => T;

export default <T>(cb: SelectorFn<T>) => {
  return useSelector((state: WidgetState) =>
    cb(state.state, state.host, state.workerReady),
  );
};
